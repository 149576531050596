/* ------------------------------------------------------- */
/* -------------- COURSES ------------------------------- */
/* ------------------------------------------------------- */

.has-margin{
	padding: 2%;
  }

.items-content {
	position: relative;
    float: left;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
}

.items-content > .box {
	position: relative;
	float: left;
	width: 100%;
	background-color: #f2f2f2;
	
	-webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

	.items-content > .box > .photo-container {
		position: relative; 
		float: left; 
		width: 100%; 
		padding: 50% 0px;
	}
		.items-content:hover > .box:after {
			content:'\A';
			pointer-events: none;
			position:absolute;
			width:100%; 
			height:100%;
			top:0; 
			left:0;
			opacity:1;
			background-image: -webkit-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 1) 30%, rgba(var(--card-hover-rgb, 0,0,0), 0.4) 60%);
			background-image: -moz-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 1) 30%, rgba(var(--card-hover-rgb, 0,0,0), 0.4) 60%);
			background-image: -ms-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 1) 30%, rgba(var(--card-hover-rgb, 0,0,0), 0.4) 60%);
			background-image: -o-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 1) 30%, rgba(var(--card-hover-rgb, 0,0,0), 0.4) 60%);
			background-image: linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 1) 30%, rgba(var(--card-hover-rgb, 0,0,0), 0.4) 60%);
		}

 		.items-content:hover > .radius:after{
			border-radius: 50px;
		}

		.items-content > .box > .photo-container > .photo {
			position: absolute; 
			top: 0px; 
			left: 0px; 
			bottom: 0px; 
			right: 0px; 
			background-position: center center; 
			background-repeat: no-repeat; 
			background-size: cover;
		}
			

		.items-content > .box > .info {
			position: relative;
			float: left;
			width: 100%;
			height: 140px;
		}


		.items-content > .box > .photo-container > .extra {
			position: absolute;
			bottom: 0px;
			background: rgb(0,0,0);
			background: rgba(0,0,0,0.4);
			display: flex;
			width: 100%;
			padding: 5px 15px;
			justify-content: space-between;
			height: 68px;
		}

		.items-content > .box > .photo-container > .extra > .date {
			font-weight: 100;
			/* width: 100%; */
			font-size: 16px;
			text-align: left;
			line-height: initial;
			display: -webkit-box;
			overflow: hidden;
			padding: 5px 35px 5px 2px;
			z-index: 10;
			transition: 0.3s;
			color: white;
		}

		.items-content > .box > .photo-container > .extra > .when {
			font-weight: 100;
			font-size: 16px;
			text-align: left;
			line-height: initial;
			display: flex;
			align-items: center;
			z-index: 10;
			transition: 0.3s;
			color: white;
			overflow: hidden;
		}

		.items-content > .box > .photo-container > .extra .date > .date-number{
			font-size: 36px;
		}

		.items-content:hover > .box > .photo-container .extra > .author {
			padding: 5px 100px 5px 2px;
			color: var(--title-hover)!important;
		}

		.items-content > .box > .photo-container > .extra >	.hour-container {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
		}

	.items-content > .box > .photo-container > .extra .thematic-pill {
		font-size: 12px;
		padding: 4px 10px;
		font-weight: lighter;
		color: black;
		background: white;
		text-align: left;
		line-height: initial;
		display: -webkit-box;
		overflow: hidden;
		-webkit-box-orient: vertical;
		border-radius: 20px;
		z-index: 2;
		display: flex;
		justify-content: center;
	}

	.items-content > .box > .photo-container > .extra .hour {
		/* text-transform: uppercase; */
		font-size: 12px;
		padding: 4px 10px 2px;
		font-weight: 100;
		font-weight: lighter!important;
		color: white;
		text-align: left;
		line-height: initial;
		display: -webkit-box;
		z-index: 2;
		display: flex;
		justify-content: center;
	}

	@media (max-width: 768px) {
		.items-content > .box > .photo-container > .extra { 
			padding: 5px 10px;
		}
		.items-content > .box > .photo-container > .extra > .date {
			font-size: 20px;
		}

		.items-content > .box > .photo-container > .extra .date > .date-number{
			font-size: 48px;
			margin-right: 5px;
		}

		.items-content > .box > .photo-container > .extra .hour {
			font-size: 16px;
		}

		.items-content > .box > .photo-container > .extra .thematic-pill { 
			font-size: 16px;
		}

		.items-content > .box > .info > .title {
			padding: 15px 10px!important;
		}

		.items-content > .box > .info > .text{
			padding: 0 10px!important;
		}
	}

.items-content > .box > .info > .title {
		position: relative;
		float: left;
		z-index: 100;
		width: 100%;
		padding: 20px 20px 15px 20px;
		font-size: 13px;
		font-weight: 600;
		color: var(--title-normal);
		text-align: left;
		line-height: initial;
		/* autoprefixer: off */
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-moz-line-clamp: 2;
		-ms-line-clamp: 2;
		-o-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
		.items-content:hover > .box > .info > .title {
			color: var(--title-hover)!important;
		}

	.items-content > .box > .info > .text{
		position: relative;
		float: left;
		z-index: 100;
		width: 100%;
		height: 70px;
		padding: 0 20px 0px 20px;
		font-size: 12px;
		color: var(--text-normal);
		text-align: left;
		font-weight: 100;
		
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: initial;
		/* autoprefixer: off */
		-webkit-line-clamp: 5;
		-moz-line-clamp: 5;
		-ms-line-clamp: 5;
		-o-line-clamp: 5;
		line-clamp: 5;
		-webkit-box-orient: vertical;
	}
		
		.items-content:hover > .box > .info > .text {
			color: var(--text-hover)!important;
		}


.items-content > .box > .icons {
	position: absolute;
	right: 20px;
	width: 150px;
	height: 60px;
	text-align: right;
	top: -70px;
	transition: all .4s ease-in-out;
	z-index: 3;
	opacity: 0;
}
	.items-content:hover > .box > .icons {
		opacity: 1;
		visibility: visible;
		top: 20px;
		transform: translateY(0);
	}


.items-content > .box > .icons > .star {
	position: relative;
	float: right;
	z-index: 100;
    width: 20px;
	margin-left: 10px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
	
	.items-content > .box > .icons > .star > svg polygon {
		display: none;
	}
	.items-content > .box > .icons > .star > svg path {
		fill: #ffffff;
	}
	.items-content > .box > .icons > .star:hover > svg polygon,
	.items-content > .box > .icons > .star:hover > svg polygon,
	.items-content > .box > .icons > .star.active > svg polygon,
	.items-content > .box > .icons > .star.active > svg polygon {
		display: block;
		fill: #ffffff;
		stroke: #ffffff;
	}

.items-content > .box > .icons > .list {
	position: relative;
	float: right;
	z-index: 100;
    width: 20px;
	margin-left: 10px;
	cursor: pointer;
	transition: all .3s ease-in-out;
}
	
		.items-content > .box > .icons > .list > svg path {
			fill: #ffffff;
		}
		.items-content > .box > .icons > .list:hover > svg path {
			stroke: #ffffff;
		}

.limit-reached {
	position: absolute;
	z-index: 10;
	right: 20px;
	fill: white;
	width: 40px;
	height: 40px;
}